<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
        <router-link :to="'/estadisticas'">Estadísticas</router-link> /
        <router-link :to="'/estadistica/' + categoria">{{itemCategoria.nombre}}</router-link> /
        <span class="active">{{ item.titulo }}</span>
      </template>
      <template slot="area-titulo">
        <h4>{{ item.titulo }}</h4>

        <small class="date">
          <i class="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z"
                fill="#666666"
              />
            </svg>
          </i>
          {{ item.publicado }}</small
        >
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="mt-4">
            <h3 class="text-center titulo" data-aos="fade-up">{{ item.titulo }}</h3>            
            <div class="text-center" v-if="tipoPublicacion">
              <span ><strong>{{item.estadistica.carga}} </strong>
                <span v-if="item.estadistica.iddepartamento!=0"> : {{item.estadistica.departamento}}  <span v-if="item.estadistica.idmunicipio!=0"> - {{item.estadistica.municipio}}</span></span> 
                <span v-if="item.estadistica.idcarga!=1"> | {{item.estadistica.fechadel}} - {{item.estadistica.fechaal}}</span>
              </span> 
            </div>
            <div class="text-center" v-if="tipoPublicacion">
              <span v-if="item.estadistica.iddelito!=0"> {{item.estadistica.delito}}</span>
            </div>
            <div class="mt-3 mb-3" v-if="item.imagen">
              <img
                v-lazy="item.imagen"
                class="img-fluid img-responsive"
                :alt="item.titulo"
                :title="item.titulo"
              />
            </div>
            <blockquote v-if="item.descripcion" class="mt-3 text-content" v-html="item.descripcion"></blockquote>
            <div class="text-content" v-if="tipoPublicacion">
              <div class="text-center">
                <span ><strong>{{item.estadistica.carga}} </strong>
                  <span v-if="item.estadistica.iddepartamento!=0"> : {{item.estadistica.departamento}}  <span v-if="item.estadistica.idmunicipio!=0"> - {{item.estadistica.municipio}}</span></span> 
                  <span v-if="item.estadistica.idcarga!=1"> | {{item.estadistica.fechadel}} - {{item.estadistica.fechaal}}</span>
                </span> 
              </div>
              <div class="text-center">
                <span v-if="item.estadistica.iddelito!=0"> {{item.estadistica.delito}}</span>
              </div>
              <table class="table">
                <thead>
                  <th>
                    <span v-if="item.estadistica.iddelito==0 || item.estadistica.iddepartamento!=0">Delitos</span>
                    <span v-else>Departamento</span>
                  </th>
                  <th>Casos Cerrados</th>
                  <th>Casos Abiertos</th>
                  <th>Casos Totales</th>
                </thead>
                <tbody>
                  <tr v-for="(items,index) in json_respuesta" :key="index">
                    <td>
                      <span v-if="item.estadistica.iddelito==0 || item.estadistica.iddepartamento!=0">{{items.Delito}}</span>
                      <span v-else>{{items.departamento}}</span>
                    </td>
                    <td>{{items.casos_cerrados}}</td>
                    <td>{{items.casos_abiertos}}</td>
                    <td>{{items.casos_total}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-3 text-content" data-aos="fade-up" v-html="item.contenido"></div>
            <p class="text-end">
              <share-it :targets="['twitter', 'facebook']" round outline>
                <template v-slot:twitter-label>
                  <label>Compartir</label>
                </template>
                <template v-slot:facebook-label>
                  <label>Compartir</label>
                </template>
              </share-it>
            </p>
          </div>
        </div>
      </div>
      <div id="navegacion" class="mt-5 mb-5 row">
        <div
          class="col-md-6 col-sm-6 col-xs-6 text-end"
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <div v-if="item_ant">
            <router-link
              class="btn btn-sm btn-fiscalia-white"
              :to="'/estadistica/'+ categoria + '/' + item_ant.url"
            >
              <i class="icon me-3"
                ><svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.318951 8.49124L9.50928 15.8744C9.57983 15.9307 9.6701 15.9625 9.76548 15.9632L11.9298 15.9776C12.1107 15.9788 12.1952 15.7739 12.059 15.6658L3.54008 8.82086L17.8512 8.91627C17.9588 8.91699 18.0474 8.8372 18.048 8.73895L18.057 7.39924C18.0576 7.30099 17.9701 7.22002 17.8625 7.2193L3.55384 7.12391L12.1633 0.393116C12.301 0.28462 12.2192 0.0808753 12.0382 0.0796688L9.80055 0.0647503C9.75408 0.0644406 9.70751 0.0797612 9.67308 0.108559L0.326156 7.41053C0.241048 7.47719 0.172614 7.55976 0.125475 7.65266C0.0783361 7.74557 0.0535909 7.84665 0.0529079 7.94909C0.052225 8.05153 0.0756202 8.15293 0.121516 8.24646C0.167412 8.33998 0.234739 8.42346 0.318951 8.49124Z"
                    fill="#F48C06"
                  /></svg
              ></i>
              Anterior
            </router-link>
            <p class="mt-3 d-none d-sm-block">{{ item_ant.titulo }}</p>
          </div>
        </div>
        <div
          class="col-md-6 col-sm-6 col-xs-6 text-start"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <div v-if="item_sig">
            <router-link
              class="btn btn-sm btn-fiscalia-white"
              :to="'/estadistica/'+ categoria + '/' + item_sig.url"
            >
              Siguiente
              <i class="ms-3 icon"
                ><svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7303 7.40879L8.491 0.0870833C8.42008 0.0312606 8.3296 0 8.23422 0H6.06989C5.88892 0 5.80577 0.205427 5.94272 0.312607L14.5071 7.10065H0.195645C0.0880404 7.10065 0 7.18103 0 7.27928V8.61902C0 8.71727 0.0880404 8.79766 0.195645 8.79766H14.5046L5.94028 15.5857C5.80333 15.6951 5.88648 15.8983 6.06745 15.8983H8.30514C8.35161 15.8983 8.39807 15.8827 8.43231 15.8536L17.7303 8.48951C17.815 8.42229 17.8829 8.33927 17.9294 8.24605C17.9759 8.15283 18 8.05159 18 7.94915C18 7.84671 17.9759 7.74546 17.9294 7.65225C17.8829 7.55903 17.815 7.47601 17.7303 7.40879Z"
                    fill="#F48C06"
                  /></svg
              ></i>
            </router-link>
            <p class="mt-3 d-none d-sm-block">{{ item_sig.titulo }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import TituloSeccion from "../secciones/TituloSeccion.vue";
export default {
  components: {
    TituloSeccion,
  },
  data() {
    return {
      item: [],
      item_ant: [],
      item_sig: [],
      url: "",
      reading: false,
      synth: window.speechSynthesis,
      utterance: null,
      preview: "",
      descripcion: "",
      json_respuesta:'',
      categoria:'',
      slug:'',
      tipoPublicacion:false,
      itemCategoria:'',
    };
  },
  metaInfo() {
    return {
      title: "Ministerio Público",
      htmlAttrs: {
        lang: "es-ES",
      },
      titleTemplate: "%s | " + this.item.titulo,
      meta: [
        { vmid: "description", name: "description", content: this.descripcion },

        { vmid: "og:locale", property: "og:locale", content: "es_LA" },
        {
          vmid: "fb:app_id",
          property: "fb:app_id",
          content: "1355122517943304",
        },
        { vmid: "og:type", property: "og:type", content: "article" },
        { vmid: "og:title", property: "og:title", content: this.item.titulo },
        { vmid: "og:image", property: "og:image", content: this.item.imagen },
      ],
    };
  },
  created() {
    this.url = window.location.pathname;
  },
  mounted() {
    if (this.$route.params.slug && this.$route.params.categoria) {
      if (this.$route.query.preview) {
        this.preview=this.$route.query.preview;
      }
      this.categoria = this.$route.params.categoria;
      this.slug = this.$route.params.slug;
      this.getPublicacion(this.categoria, this.slug);
    }
  },
  watch: {
    $route: function (val) {
      if (val.params.slug && val.params.categoria) {
        this.categoria = val.params.categoria;
        this.slug = val.params.slug;
        this.getPublicacion(this.categoria, this.slug);
      }
    },
  },
  methods: {
    getPublicacion(categoria, slug){
      var url= '/web/estadistica/' + categoria + '/' + slug+'?preview='+this.preview;
      axiosIns
        .get(url)
        .then((res) => {
          if (res.data.actual) {
            if (res.data.actual.estadistica) {
                this.tipoPublicacion=true;
                this.item = res.data.actual;
                this.itemCategoria=res.data.actual.categoria;
                this.descripcion = this.item.titulo + " " + this.item.descripcion;
                this.descripcion = this.descripcion
                  .replace(/(<(?:.|\n)*?>)/gm, " ")
                  .replace(/\s+/gm, " ");
                this.item_ant = res.data.anterior;
                this.item_sig = res.data.siguiente;
                if (this.item.estadistica.estatico==0) {
                    this.json_respuesta=JSON.parse(this.item.estadistica.json_respuesta);
                }else{
                  this.json_respuesta=this.item.estadistica.json_respuesta.response;
                }
            }else{
                this.tipoPublicacion=false;
                this.item = res.data.actual;
                this.itemCategoria=res.data.actual.categoria;
                this.descripcion = this.item.titulo + " " + this.item.descripcion;
                this.descripcion = this.descripcion
                  .replace(/(<(?:.|\n)*?>)/gm, " ")
                  .replace(/\s+/gm, " ");
                this.item_ant = res.data.anterior;
                this.item_sig = res.data.siguiente;
            }
          } else {
            this.item_ant = [];
            this.item_sig = [];
            this.$router.push({ name: "error-404" });
          }
        })
        .catch((err) => {
          this.item_ant = [];
          this.item_sig = [];
          console.log(err);
        });
    },

    leerNoticia() {
      let regex = /(<([^>]+)>)/gi;
      var texto = this.item.contenido.replace(regex, " ");
      //speechSynthesis.speak(new SpeechSynthesisUtterance(texto));
      if (this.utterance == null || this.synth.pending) {
        this.utterance = new SpeechSynthesisUtterance(texto);
        this.utterance.lang = "es-ES";
        this.synth.speak(this.utterance);
      } else {
        this.synth.resume();
      }

      this.reading = true;
    },
    pausarNoticia() {
      this.synth.pause();
      this.reading = false;
    },
  },
};
</script>

<style>
</style>
